import { graphql, useStaticQuery } from "gatsby"
// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { FunctionComponent } from "react"
import styled from "styled-components"
import {
  ContentWrapper,
  Description,
  // Image,
  TextWrapperWrapper,
  Title,
  Wrapper,
} from "../../styles/HeroElement"
// import Circle from "../cards/Circle"
// import Enquirebutton from "../buttons/Enquirebutton"

interface AccomodationSectionProps {
  title?: string
  description?: string
  // image?: string
}

const AccomodationSection: FunctionComponent<AccomodationSectionProps> =
  props => {
    const {
      title = "Accommodation",
      description = "We will help with finding short-term, long-term and student accommodation at top-tier facilities with premium service to cater to your every desire.",
      // image = "/assets/backgrounds/accommodatation.jpeg",
    } = props

    const data = useStaticQuery(graphql`
      query MyQuery {
        file(relativePath: { eq: "accommodation.jpeg" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 10)
          }
        }
      }
    `)

    return (
      <Wrapper>
        <ContentWrapper>
          <TextWrapperWrapper>
            <Title>{title}</Title>
            <Description>{description}</Description>
            {/* <Enquirebutton /> */}
          </TextWrapperWrapper>
          <FileWrapper>
            <GatsbyImage
              alt="image"
              image={data.file.childImageSharp.gatsbyImageData}
              style={styles}
            />
          </FileWrapper>
        </ContentWrapper>
      </Wrapper>
    )
  }

export default AccomodationSection

const FileWrapper = styled.div``

const styles = {
  borderRadius: "20px",
}
