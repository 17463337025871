import React, { FunctionComponent } from "react"
import EnquireButton from "../components/buttons/Enquirebutton"
import Layout from "../components/layout/Layout"
import SEO from "../components/layout/SEO"
import Line from "../components/lines/Line"
import AccomodationHero from "../components/sections/AccomodationHero"
import AccomodationSection from "../components/sections/AccomodationSection"
import styled from "styled-components"
import AccommodationNew from "../components/sections/AccomodationNew"
interface AccomodationProps {
  title: string
  name: string
  content: string
}

const Accomodation: FunctionComponent<AccomodationProps> = props => {
  const {
    title = "accommodation",
    name = "Keywords",
    content = "Education",
  } = props
  return (
    <Layout>
      <SEO title={title} />
      <meta name={name} content={content} />
      <AccomodationSection />
      <LineWrapper>
        <Line />
      </LineWrapper>
      <AccomodationHero />
      <AccommodationNew />
      <ButtonWrapper>
        <EnquireButton />
      </ButtonWrapper>
    </Layout>
  )
}

export default Accomodation

const LineWrapper = styled.div`
  margin: 50px 0 0 0;
  display: grid;
  justify-content: center;
`
const ButtonWrapper = styled.div`
  display: grid;
  justify-content: center;
  padding: 0 0 120px 0;
  @media (max-width: 640px) {
    padding: 10px 0 40px 0;
  }
`
