import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { H3, H4 } from "../../styles/TextStyles"

interface AccommodationNewProps {}

const AccommodationNew: FunctionComponent<AccommodationNewProps> = () => {
  return (
    <Wrapper>
      <TextWrapper>
        <Title>Long term, Short term and Student</Title>
        <Description>
          We at Joshua Organisation can help you with all your accommodation
          needs whether its
          <span> short term, long term, even student accommodation</span>.
        </Description>
        <Description>
          We will help you find the best accommodation at top tier facilities
          with premium service to cater for your every desire.
        </Description>
        <Description>
          We offer self-catering apartments and exquisite hotels stays that will
          leave you breathless and wanting to come back for me.
        </Description>
        <Subtitle>Types of Accommodation offered:</Subtitle>
        <List> GUEST HOUSES</List>
        <List> FLATS</List>
        <List> APARTMENTS</List>
        <List> HOTEL ROOMS</List>
      </TextWrapper>
    </Wrapper>
  )
}

export default AccommodationNew

const Wrapper = styled.div`
  padding: 0px 0 0px 0;
`
const TextWrapper = styled.div`
  max-width: 820px;
  display: grid;
  margin: 0 auto;
  gap: 20px;
  @media (max-width: 640px) {
    max-width: 300px;
  }
`
const Title = styled(H3)`
  @media (max-width: 640px) {
    font-size: 18px;
    font-weight: 500;
  }
`
const Description = styled.p`
  font-size: 14px;
  line-height: 180%;
  span {
    font-weight: bold;
  }
`
const Subtitle = styled(H4)`
  @media (max-width: 640px) {
    font-size: 18px;
  }
`
const List = styled.li`
  font-size: 14px;
`
