import { Link } from "gatsby"
import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { H4 } from "../../styles/TextStyles"

interface EnquireButtonProps {
  title?: string
}

const EnquireButton: FunctionComponent<EnquireButtonProps> = ({
  title = "Enquire now",
}) => {
  return (
    <Button>
      <Link to="/enquire">
        <Wrapper>
          <Title>{title}</Title>
        </Wrapper>
      </Link>
    </Button>
  )
}

export default EnquireButton
const Button = styled.div`
  a {
    text-decoration: none;
  }
`
const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 77px;
  background: blue;
  color: white;
  border-radius: 12px;
  font-size: 17px;
  cursor: pointer;
  font-weight: 400;
  @media (max-width: 640px) {
    width: 230px;
    height: 55px;
  }
`
const Title = styled(H4)``
