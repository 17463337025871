import React, { FunctionComponent } from "react"
import styled from "styled-components"

interface LineProps {
  title?: string
}

const Line: FunctionComponent<LineProps> = props => {
  const { title = "Think Big, Think Joshua Organisation" } = props
  return (
    <Wrapper>
      <Title>{title}</Title>
    </Wrapper>
  )
}

export default Line

const Wrapper = styled.div`
  padding: 30px 0 30px 0;
  width: 1438px;
  background: blue;
  height: 80px;

  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: #fff;
    }
  }
  @keyframes typing {
    0% {
      width: 0%;
    }
    30% {
      width: 100%;
    }
    80% {
      width: 100%;
    }
    90% {
      width: 0%;
    }
    100% {
      width: 0%;
    }
  }

  @media (max-width: 1024px) {
    width: 800px;
  }
  @media (max-width: 768px) {
    width: 700px;
  }
  @media (max-width: 640px) {
    width: 300px;
    height: 40px;
  }
`
const Title = styled.h1`
  /* color: rgba(103, 128, 159, 1); */
  color: white;
  text-transform: uppercase;

  text-align: center;
  font-size: 19px;
  @media (max-width: 640px) {
    font-size: 14px;
  }
  //Aninmation
  white-space: nowrap;
  overflow: hidden;
  animation: typing 15s steps(300) forwards, blink-caret 0.15s step-end infinite;
  animation-iteration-count: infinite;
`
