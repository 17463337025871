import styled from "styled-components"
import { H1, Paragraph } from "./TextStyles"

export const Wrapper = styled.div`
  padding: 100px 100px 0 100px;
`
export const ContentWrapper = styled.div`
  justify-content: center;
  max-width: 1234px;
  display: grid;
  grid-template-columns: 500px auto;
  gap: 40px;
  align-items: center;
  @media (max-width: 1024px) {
    grid-template-columns: 500px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 500px;
  }
  @media (max-width: 640px) {
    grid-template-columns: auto;
  }
`
export const TextWrapperWrapper = styled.div`
  max-width: 500px;
  display: grid;
  gap: 30px;

  @media (max-width: 640px) {
    max-width: 360px;
    grid-template-columns: 300px;
  }
`
export const FileWrapper = styled.div``
export const Image = styled.img`
  width: 600px;
  border-radius: 20px;
  @media (max-width: 1024px) {
    width: 500px;
  }
  @media (max-width: 768px) {
    width: 500px;
  }
  @media (max-width: 640px) {
    width: 300px;
  }
`

export const Title = styled(H1)`
  @media (max-width: 640px) {
    width: 300px;
    font-size: 30px;
  }
  color: black;
  font-weight: bold;
`
export const Description = styled(Paragraph)`
  line-height: 160%;
`
