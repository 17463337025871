import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { H2, Paragraph } from "../../styles/TextStyles"

interface AccomadationProps {
  title?: string
  subtitle?: string
}

const AccomodationHero: FunctionComponent<AccomadationProps> = props => {
  const {
    title = "Find your place ",
    subtitle = "We offer self-catering apartments and exquisite hotels stays that will leave you breathless and wanting to come back for me.",
  } = props
  return (
    <Wrapper>
      <ContentWrapper>
        <TextWrapper>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </TextWrapper>
        <ListWrapper>
          <Image src="assets/backgrounds/c1.jpeg" />
          <Image src="assets/backgrounds/c2.jpeg" />
          <Image src="assets/backgrounds/c3.jpeg" />
        </ListWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

export default AccomodationHero

const Wrapper = styled.div`
  padding: 100px 38px;
`
const ContentWrapper = styled.div`
  display: grid;
  gap: 50px;
`
const Title = styled(H2)`
  font-weight: 700;
  @media (max-width: 640px) {
    font-size: 30px;
    line-height: 130%;
  }
`
const Subtitle = styled(Paragraph)`
  max-width: 600px;
  line-height: 130%;
`
const Image = styled.img`
  border-radius: 10px;
  width: 400px;
  height: 300px;
  @media (max-width: 1024px) {
    width: 300px;
    height: 200px;
  }
  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
  }
  @media (max-width: 640px) {
    width: 300px;
    height: 200px;
  }
`
const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: 400px 400px 400px;

  @media (max-width: 1024px) {
    grid-template-columns: 300px 300px 300px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 200px 200px 200px;
  }
  gap: 20px;
  justify-content: center;
  @media (max-width: 640px) {
    grid-template-columns: 300px;
  }
`
const TextWrapper = styled.div`
  display: grid;
  gap: 20px;
`
